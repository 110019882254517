<template>
    <div>
        <div class="container-pa16">
            <div class="store-img">
                <vueper-slides
                    v-if="Array.isArray(store.banner)"
                    ref="storeBanner"
                    class="carousel-ad no-shadow"
                    autoplay
                    fixed-height="160px"
                    :arrows="false"
                >
                    <vueper-slide
                        v-for="item in store.banner"
                        :key="item"
                        :image="`https://s3lvelect.s3.ap-east-1.amazonaws.com/${item}`"
                    />
                </vueper-slides>
                <img
                    v-else
                    :src="`https://s3lvelect.s3.ap-east-1.amazonaws.com/${store.banner}`"
                    alt=""
                />
            </div>
            <div class="inform center">
                <h1 class="title">{{ store.name }}</h1>
                <div class="inform-detail">
                    <div class="margin-bottom-16">
                        <div class="flex-container space-between flex-center">
                            <img
                                :src="require('@/assets/imgs/svg/0527-l.svg')"
                            />
                            <span>{{ store.time }}</span>
                        </div>
                        <hr class="gray-hr" />
                    </div>
                    <div class="margin-bottom-16">
                        <div class="flex-container space-between flex-center">
                            <img
                                :src="require('@/assets/imgs/svg/0527-m.svg')"
                            />
                            <span>{{ store.phone }}</span>
                        </div>
                        <hr class="gray-hr" />
                    </div>
                    <div class="margin-bottom-16">
                        <div class="flex-container space-between flex-center">
                            <img
                                :src="require('@/assets/imgs/svg/0603-i.svg')"
                            />
                            <span>{{ store.address }}</span>
                        </div>
                        <hr class="gray-hr" />
                    </div>
                    <div class="margin-bottom-8">
                        <div class="flex-container space-between flex-center">
                            <img
                                :src="require('@/assets/imgs/svg/0603-o.svg')"
                            />
                            <span @click="$_href(store.url)">{{
                                store.url
                            }}</span>
                        </div>
                        <hr class="gray-hr" />
                    </div>
                </div>
                <span class="text-1250"
                    >可借：{{ store.unUse }} / 可還：{{ store.return }}</span
                >
                <br />
                <br />
            </div>
            <center>
                <button
                    type="button"
                    class="capsule-button text-1250 capsule-big"
                    @click="toScanner()"
                >
                    立即租借
                </button>
            </center>
            <div class="bottom-name">
                <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
            </div>
        </div>
        <!-- <div
            v-if="showBindAlert"
            class="light-box flex-container flex-center center flex-center-h"
            @click.self="showBindAlert = false"
        >
            <div class="box flex-container flex-column flex-center center">
                <span class="text-1500 text-b title">提醒</span>
                <span class="msg text-1250"
                    >如用戶未設定支付方式，跳至我的錢包選擇支付方式，若已設定過，請開啟相機</span
                >
                <button
                    type="button"
                    class="capsule-button text-875 capsule-small check"
                    @click="toScanner()"
                >
                    前往
                </button>
            </div>
        </div> -->
        <div
            v-if="showServiceAlert"
            class="light-box flex-container flex-center center flex-center-h"
            @click.self="showServiceAlert = false"
        >
            <div class="box flex-container flex-column flex-center center">
                <span class="text-1500 text-b title">提醒</span>
                <span class="msg text-1250"
                    >尚有訂單租借中，待歸還行動電源後，才可重新租借</span
                >
                <div class="flex-container flex-center margin-top-28">
                    <img
                        class="front-img-w14"
                        :src="require('@/assets/imgs/svg/0527-l.svg')"
                    />
                    <span class="text-875 text-b">10:00-18:00</span>
                </div>
                <div class="flex-container flex-center">
                    <img
                        class="front-img-w14"
                        :src="require('@/assets/imgs/svg/0527-m.svg')"
                    />
                    <span class="text-875 text-b">(02)2926-6681</span>
                </div>
                <div class="flex-container flex-center margin-bottom-28">
                    <img
                        class="front-img-w14"
                        :src="require('@/assets/imgs/svg/0527-n.svg')"
                    />
                    <span class="text-875 text-b">pluginn.info@gmail.com</span>
                </div>
                <button
                    type="button"
                    class="capsule-button text-875 capsule-small check"
                    @click="$_serviceLightBoxOnpress()"
                >
                    確認
                </button>
            </div>
        </div>
        <div
            v-if="showRentDeviceSuccess"
            class="light-box flex-container flex-center center flex-center-h"
            @click.self="showRentDeviceSuccess = false"
        >
            <div class="box flex-container flex-column flex-center center">
                <span class="text-1500 text-b title">租借成功</span>
                <div target="blank" @click="$_advClick()">
                    <img
                        class="image"
                        :src="`https://s3lvelect.s3.ap-east-1.amazonaws.com/${store.advImage}`"
                        alt=""
                    />
                </div>
                <button
                    type="button"
                    class="capsule-button text-875 capsule-small check"
                    @click="$_successLightBoxOnpress()"
                >
                    確認
                </button>
            </div>
        </div>
        <div v-if="showBindAlert || showServiceAlert" class="block"></div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import { Dialog } from "vant";
import { Toast } from "vant";

export default {
    beforeCreate() {
        const setStore = async () => {
            try {
                const storeInfoId = this.$route.params.storeID;
                localStorage.setItem("storeID", storeInfoId);
                // const token = localStorage.getItem("token");
                const token = window.$cookies.get("token");

                if (token) {
                    this.$axios.defaults.headers.common["token"] = token;
                    this.$axios.defaults.headers.common["appType"] = "PHONE";
                }
                const res = await this.$axios.get(
                    `customer/store/detail?storeInfoId=${storeInfoId}`
                );
                if (res.data.success) {
                    const store = res.data.result.storeInfoDto;
                    const storeBanner = res.data.result.advImgList;
                    this.store = Object.assign(
                        {},
                        {
                            id: store.agentId,
                            name: store.storeName,
                            time: `${store.beginTime} - ${store.endTime}`,
                            phone: store.servicePhone,
                            address: store.address,
                            url: store.platformAdvLink,
                            unUse: store.unNum,
                            return: store.total - store.unNum,
                            devices: res.data.result.deviceSnList,
                            banner:
                                storeBanner.length > 0
                                    ? storeBanner
                                    : store.platformAdvImg,
                            advImage: store.storeAdvImg,
                            advLink: store.storeAdvLink,
                        }
                    );
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        };
        this.isLoading = true;
        setStore();
    },
    watch: {
        "$route.params": {
            deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue.rentSuccess) {
                    this.showRentDeviceSuccess = true;
                }
            },
        },
    },
    data() {
        return {
            isLoading: true,
            ad: [],
            store: {
                name: "",
                time: "",
                phone: "",
                address: "",
                url: "",
                unUse: "0",
                return: "0",
                devices: [],
                banner: "",
            },
            customerStatus: "",
            showBindAlert: false,
            showServiceAlert: false,
            showRentDeviceSuccess: false,
        };
    },
    methods: {
        $_href(url) {
            window.location.href = url;
        },
        // async $_validateCustomer() {
        //     try {
        //         const res = await this.$axios.get("customer/order/validate");
        //         console.log(res, "validateCustomer");
        //         if (res.data.success) {
        //             const customerStatus = res.data.result.status;
        //             switch (customerStatus) {
        //                 case 1:
        //                     this.customerStatus = "pass";
        //                     this.$_validateCustomerDeposit();
        //                     break;
        //                 case 2:
        //                     this.customerStatus = "depositUnpaid";
        //                     break;
        //                 case 3:
        //                     this.customerStatus = "orderUnpaid";
        //                     break;
        //                 case 4:
        //                     this.customerStatus = "orderInUse";
        //                     break;
        //                 case 5:
        //                     this.customerStatus = "cardUnbound";
        //                     break;
        //             }
        //             if (this.customerStatus !== "pass" && this.customerStatus) {
        //                 this.$_checkCustomerStatus();
        //             }
        //         } else {
        //             throw res;
        //         }
        //     } catch (error) {
        //         handleErr({ ...error, app: this.$parent });
        //     }
        // },
        async $_validateCustomerDeposit() {
            try {
                const res = await this.$axios.get("customer/user-info");
                console.log(res, "user-info");
                if (res.data.success) {
                    if (!res.data.result.freeDeposit) {
                        this.customerStatus = "cardUnbound";
                    }
                    this.$_checkCustomerStatus();
                } else {
                    throw res;
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
        $_serviceLightBoxOnpress() {
            this.showServiceAlert = false;
        },
        $_successLightBoxOnpress() {
            this.showRentDeviceSuccess = false;
        },
        // async $_bindLightBoxOnpress() {
        //     this.showBindAlert = false;
        //     try {
        //         const res = await this.$axios.get("linePay/saveKey");
        //         if (res.data.success) {
        //             window.location.href = res.data.result;
        //         } else {
        //             throw res;
        //         }
        //     } catch (error) {
        //         handleErr({ ...error, app: this.$parent });
        //     }
        // },
        $_checkCustomerStatus() {
            if (this.customerStatus === "pass") {
                localStorage.setItem(
                    "devices",
                    JSON.stringify(this.store.devices)
                );
                this.$router.push("/confirm-device");
            } else if (
                this.customerStatus === "cardUnbound" ||
                this.customerStatus === "depositUnpaid"
            ) {
                this.showBindAlert = true;
            } else if (
                this.customerStatus === "orderUnpaid" ||
                this.customerStatus === "orderInUse"
            ) {
                this.showServiceAlert = true;
            }
        },
        // async $_rentPowerbank() {
        //     try {
        //         await this.$_validateCustomer();
        //     } catch (error) {
        //         handleErr({ ...error, app: this.$parent });
        //     }
        // },
        $_advClick() {
            this.$liff.openWindow({
                url: this.store.advLink,
                external: true,
            });
        },
        async toScanner() {
            try {
                this.deviceNo = "";
                await this.$_validateCustomer();
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        async $_validateCustomer() {
            try {
                const res = await this.$axios.get("customer/order/validate");
                if (res.data.success) {
                    const customerStatus = res.data.result.status;
                    switch (customerStatus) {
                        case 1:
                            this.customerStatus = "pass";
                            this.$router.push("/scanner");
                            break;
                        case 2:
                            this.customerStatus = "depositUnpaid";
                            Dialog.confirm({
                                title: "請綁定支付方式",
                                message: "",
                                confirmButtonText: "確認",
                            })
                                .then(() => {
                                    console.log("confirm");
                                    // this.$_bindLightBoxOnpress();
                                    this.$router.push("/DepositMethod");
                                })
                                .catch(() => {
                                    console.log("cancel");
                                });

                            break;
                        case 3:
                            this.customerStatus = "orderUnpaid";
                            this.$router.push("/records");
                            break;
                        case 4:
                            this.customerStatus = "orderInUse";
                            Toast("你有未完成的訂單");
                            break;
                        case 5:
                            this.customerStatus = "cardUnbound";
                            // Dialog.confirm({
                            // 	title: "請申請LINE PAY並綁定",
                            // 	message: "",
                            // 	confirmButtonText: "確認",
                            // })
                            // 	.then(() => {
                            // 		console.log("confirm");
                            // 		this.$_bindLightBoxOnpress();
                            // 	})
                            // 	.catch(() => {
                            // 		console.log("cancel");
                            // 	});
                            break;
                    }
                    // if (this.customerStatus !== "pass" && this.customerStatus) {
                    // 	this.$_checkCustomerStatus();
                    // }
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    mounted() {
        // console.log(
        //     localStorage.getItem("token"),
        //     "localStorage",
        //     this.$axios.defaults.headers.common["token"],
        //     "token",
        //     localStorage.getItem("storeID"),
        //     "idid"
        // );
    },
};
</script>
